import Vuex from "vuex";
import Vue from 'vue'
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    isShow: false,
    showOverlay: false,
    user:null,
    useraccount:null,
    marketList:null
  },
  mutations: {
    // 展示loading的方法
    showLoading(state) {
      state.isShow = true
    },
    // 隐藏loading的方法
    hideLoading(state) {
      state.isShow = false
    },
    showOverlay (state) {
      state.showOverlay = true;
    },
    hideOverlay (state) {
      state.showOverlay = false;
    },
    userInfo(state,payload){
      state.user = payload;
    },
    useraccount(state,payload){
      state.useraccount = payload;
    },
    //用户市场数据
    userMarketList(state,marketList){
      state.marketList = marketList;
    }
  },
  getters:{
    user: (state) => state.user,
    marketList: (state) => state.marketList
  },
  actions: {},
  modules: {}
});
export default store;