<template>
  <div>
    <div slot="header" class="clearfix">
      <span>将系统账号与微信绑定</span>
    </div>
    <div style="text-align: center">
      <van-form @submit="onSubmit">
        <van-image round width="50px" height="50px" :src="form.headimgurl" />
        <van-field readonly v-model="form.nickname" name="微信" label="微信" placeholder="微信名" />
        <van-field readonly v-model="form.openid" name="openId" label="openId" placeholder="openId" />
        <van-field v-model="form.useraccount" name="系统账号" label="系统账号" placeholder="系统账号" :rules="[{ required: true, message: '系统账号不能为空' }]" />
        <van-field name="checkboxGroup" label="所属线路" v-if="this.form.useraccount">
          <template #input>
            <van-checkbox-group v-model="form.checkList" direction="horizontal" style="justify-content: space-between" ref="checkboxGroup">
              <van-checkbox v-for="item in marketList" :key="item.id" shape="square" :name="item.id" style="margin: 5px">{{ item.marketName }}</van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
        <van-field label="选择" v-if="this.form.useraccount">
          <template #input>
            <van-button type="primary" size="small" native-type="button" @click.stop="checkAll">全选</van-button>
            <van-button style="margin-left: 10px" type="info" size="small" native-type="button" @click.stop="toggleAll">反选</van-button>
          </template>
        </van-field>
        <van-button v-if="this.form.useraccount" type="primary" round block size="large" :disabled="isDisabled" native-type="submit">绑定</van-button>
      </van-form>
    </div>
  </div>
</template>

<script>
import request from "@/api/request.js";
export default {
  name: "bindUser",
  data() {
    return {
      isDisabled: false,
      marketList: [],
      form: {
        nickname: "",
        openid: "",
        headimgurl: "",
        useraccount: "",
        marketText: "",
        checkList: [],
      },
    };
  },
  created() {
    const token = this.$cookies.get("token");
    if (!token) {
      this.form.nickname = sessionStorage.getItem("nickName");
      this.form.openid = sessionStorage.getItem("openId");
      this.form.headimgurl = sessionStorage.getItem("imgUrl");
      const queryStr = window.location.search;
      const urlParams = new URLSearchParams(queryStr);
      if (urlParams.get("u")) {
        this.form.useraccount = urlParams.get("u");
          request({
          url: "/market/getMarketAllForAccount",
          method: "get",
          params: { userAccount: this.form.useraccount },
        }).then(res=>{
            this.marketList = res.data.marketList;
        });
      } else {
          //未传参数的情况下手动绑定
          this.onSubmit();
      }
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    checkAll() {
      this.$refs.checkboxGroup.toggleAll(true);
    },
    toggleAll() {
      this.$refs.checkboxGroup.toggleAll();
    },
    onSubmit() {
      let that = this;
      this.$store.commit('showLoading');
      let marketText = this.form.checkList.join(",");
      this.form.marketText = marketText;
      request({
        url: "/oauth/bindWx",
        data: this.form,
        method: "post",
      })
        .then((res) => {
          this.$store.commit('hideLoading');
          this.isDisabled = true;
            this.$cookies.set("token", res.data.token, 60 * 60 * 24 * 7);
            this.$router.push("/welcome");
        },rej=>{
          this.$store.commit('hideLoading');
        });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less"></style>
