<template>
  <div id="app" style="height: 100%">
    <KeepAlive :include="['uploadIndex','welcome']">
      <router-view class="g-view-container"></router-view>
    </KeepAlive>
    <div class="g-footer-container">
      <tabbar />
    </div>
    <van-overlay :show="$store.state.showOverlay">
      <div class="wrapper">
        <van-loading />
      </div>
    </van-overlay>
    <van-loading v-show="isShow" size="24px" color="#1989fa" vertical>加载中...</van-loading>
  </div>
</template>

<script>
import Tabbar from "@/components/tabbar";
import { mapState } from "vuex";
export default {
  name: "App",
  components: {
    Tabbar,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["isShow"]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 14px;
  padding: 0px;
  margin: 0px;
}
#app,
html,
body {
  height: 100%;
}
.g-view-container {
  height: calc(~"100% - 50px");
  margin-bottom: 50px;
  overflow: auto;
}

.g-footer-container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0 0 10px 0 hsla(0, 6%, 58%, 0.6);
  height: 50px;
  z-index: 999;
  background: lightgreen;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.van-loading {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}
</style>
