<template>
  <div class="main">
    <div>
      <van-nav-bar left-text="返回" left-arrow @click-left="onReturn">
        <template #right>
          <van-button type="primary" @click="showLog" size="small"
            >日志记录</van-button
          >
          <van-button
            type="primary"
            v-if="indexInfo.customerServiceId == user.id"
            @click="saveIndex"
            size="small"
            style="margin-left: 10px"
            >更新线索</van-button
          >
          <van-button
            v-if="indexInfo.customerServiceId == null && user.userjurid == 6"
            type="primary"
            @click="handlebind"
            size="small"
            style="margin-left: 10px"
            >获取线索</van-button
          >
          <van-button
            v-if="
              indexInfo.customerServiceId == null && (user.userjurid == 2 || user.userjurid == 1)
            "
            type="primary"
            @click="distribute"
            size="small"
            style="margin-left: 10px"
            >分发线索</van-button
          >
        </template>
      </van-nav-bar>
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field v-model="indexInfo.operations" name="运营" label="运营" />
          <van-field
            v-model="indexInfo.customerService"
            name="客服"
            label="客服"
          />
          <van-field v-model="indexInfo.marketName" name="市场" label="市场" />
          <van-field
            readonly
            clickable
            name="picker"
            :value="indexInfo.statusText"
            label="状态"
            placeholder="状态"
            @click="showPicker = true"
          />
          <van-field name="switch" label="是否加微信">
            <template #input>
              <van-switch
                v-model="indexInfo.wx"
                active-value="1"
                inactive-value="0"
                size="20"
              />
            </template>
          </van-field>
          <van-field name="switch" label="是否成交">
            <template #input>
              <van-switch
                v-model="indexInfo.ok"
                active-value="1"
                inactive-value="0"
                size="20"
              />
            </template>
          </van-field>
          <div class="rowTextArea">
            <van-field
              v-model="indexInfo.contact"
              name="联系方式"
              label="联系方式"
            />
          </div>
          <van-field v-model="indexInfo.insertTime" name="时间" label="时间" />
          <div class="rowTextArea">
            <van-field
              v-model="indexInfo.customerInfo"
              rows="3"
              autosize
              label="客户详情"
              type="textarea"
              maxlength="50"
              placeholder="客户详情备注"
              show-word-limit
            />
          </div>
          <div v-if="indexInfo.operationsId == user.id || user.userjurid == 5">
            <van-field name="scorm" label="联系方式截图">
              <template #input>
                <van-uploader
                  max-count="1"
                  v-model="screenFile"
                  accept="image/*"
                  :before-delete="deleteScreen"
                  :before-read="beforeReadS"
                />
              </template>
            </van-field>
            <van-field name="uploader" label="详情截图">
              <template #input>
                <van-uploader
                  ref="fileUpload"
                  multiple
                  v-model="fileList"
                  accept="image/*"
                  :before-delete="deleteDetail"
                  :before-read="beforeReadD"
                />
              </template>
            </van-field>
            <van-button round block type="primary" native-type="submit"
              >保存</van-button
            >
          </div>
          <div v-else>
            <van-image
              v-if="indexInfo.printscreen != null"
              :src="indexInfo.printscreen"
            />
            <van-image
              v-for="url in indexInfo.printscreenJson"
              :key="url"
              :src="url"
            />
          </div>
        </van-cell-group>
      </van-form>
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          value-key="label"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-popup v-model="showUserPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="market_groupMember_Users"
          value-key="username"
          @confirm="onConfirmU"
          @cancel="showUserPicker = false"
        />
      </van-popup>
      <van-popup
        v-model="showPPLog"
        position="bottom"
        :style="{ height: '80%' }"
        closeable
      >
        <van-cell>
          <template #default>
            <strong>上传时间：</strong><span>{{ indexInfo.insertTime }}</span
            >&nbsp;&nbsp;&nbsp; <strong>分发时间：</strong
            ><span>{{ indexInfo.dispathTime }}</span
            >&nbsp;&nbsp;&nbsp;
          </template>
        </van-cell>
        <van-cell v-for="item in logList" :key="item.id">
          <template #default>
            <span>{{ item.username }}|{{ item.nickname }} 线索上传</span>
            <span>{{
              computeDate(indexInfo.dispathTime, item.insertTime)
            }}</span>
            &nbsp;&nbsp;&nbsp; <span>{{ formatLogType(item.type) }}</span
            >&nbsp;&nbsp;&nbsp;
          </template>
        </van-cell>
      </van-popup>
    </div>
    <van-dialog
      v-model="showDisDialog"
      title="分发线索给客服"
      show-cancel-button
    >
      <van-field
        readonly
        clickable
        name="picker"
        :value="ffusername"
        label="名称"
        placeholder="点击选择客服"
        @click="showUserPicker = true"
      />
    </van-dialog>
  </div>
</template>
<script>
import request from '@/api/request.js';
import moment from 'moment';
import { mapState } from 'vuex';
export default {
  name: 'indexDetail',
  computed: mapState(['user', 'marketList']),
  data() {
    return {
      showPicker: false,
      showUserPicker: false,
      showPPLog: false,
      showDisDialog: false,
      columns: [
        { label: '待定', text: 'danger' },
        { label: '行程中', text: 'warning' },
        { label: '已走', text: 'info' },
        { label: '已结', text: 'success' },
      ],
      fileList: [],
      screenFile: [],
      logList: [],
      indexInfo: {
        contact: '',
        customerInfo: '',
        customerService: '',
        customerServiceId: null,
        id: null,
        dispathTime: '',
        insertTime: '',
        modifyTime: '',
        marketId: null,
        marketName: '',
        ok: 0,
        operations: '',
        operationsId: null,
        printscreen: '',
        printscreenJson: [],
        printscreenText: '',
        profit: null,
        progressInfo: '',
        statusCode: '',
        statusText: '',
        wx: null,
      },
      market_groupMember_Users: [],
      ffusername: '',
      id: '',
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getIndex();
  },

  methods: {
    onReturn() {
      this.$router.push('/welcome');
    },
    showLog() {
      this.showPPLog = true;
      request({
        url: '/auditLog/get',
        method: 'get',
        params: {
          businessId: this.id,
        },
      })
        .then((res) => {
          this.logList = res.data.list;
        });
    },
    formatLogType(type) {
      switch (type) {
        case 'browse':
          return '浏览';
        case 'update':
          return '更新';
      }
    },
    computeDate(starTime, endTime) {
      let diffTime =
        moment(endTime, 'YYYY-MM-DD HH:mm:ss') -
        moment(starTime, 'YYYY-MM-DD HH:mm:ss');
      if (diffTime < 0) {
        diffTime =
          moment(starTime, 'YYYY-MM-DD HH:mm:ss') -
          moment(endTime, 'YYYY-MM-DD HH:mm:ss');
      }
      let text = '';
      let days = Math.floor(diffTime / (24 * 3600 * 1000));
      if (days > 0) text += days + '天 ';
      let leave1 = diffTime % (24 * 3600 * 1000);
      let hours = Math.floor(leave1 / (3600 * 1000));
      if (hours > 0) text += hours + '小时 ';
      let leave2 = leave1 % (3600 * 1000);
      let minutes = Math.floor(leave2 / (60 * 1000));
      if (minutes > 0) text += minutes + '分钟后';
      return text;
    },
    deleteScreen(file) {
      return this.deleteImg('screen', file);
    },
    deleteDetail(file) {
      return this.deleteImg('detail', file);
    },
    beforeReadS(file) {
      return this.addImg('screen', file);
    },
    beforeReadD(file) {
      return this.addImg('detail', file);
    },
    onConfirmU(select) {
      this.showUserPicker = false;
      this.ffusername = select.username;
      this.$store.commit('showOverlay');
      request({
        url: '/customerIndex/bindCustomerIndex',
        method: 'get',
        params: {
          id: this.id,
          userid: select.id,
          username: select.username,
        },
      })
        .then((res) => {
          this.indexInfo.customerServiceId = select.id;
            this.indexInfo.customerService = select.username;
            this.showDisDialog = false;
          this.$store.commit('hideOverlay');
        });
    },
    addImg(type, file) {
      let formdata = new FormData();
      if (Array.isArray(file)) {
        file.forEach((item) => {
          formdata.append('files', item);
        });
      } else {
        formdata.append('files', file);
      }

      return request({
        url: '/customerIndex/addImg',
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formdata,
        params: {
          id: this.id,
          type: type,
        },
      })
        .then((res) => {
          if (type == 'screen') {
            this.indexInfo.printscreen = res.data.json;
          } else if (type == 'detail') {
            this.indexInfo.printscreenText = res.data.json;
          }
        });
    },
    deleteImg(type, file) {
      return request({
        url: '/customerIndex/deleteImg',
        method: 'get',
        params: {
          id: this.id,
          type: type,
          url: file.url,
        },
      })
        .then((res) => {
          if (type == 'screen') {
            this.indexInfo.printscreen = res.data.json;
          } else if (type == 'detail') {
            this.indexInfo.printscreenText = res.data.json;
          }
        });
    },
    onConfirm(select) {
      this.showPicker = false;
      this.indexInfo.statusCode = select.text;
      this.indexInfo.statusText = select.label;
    },
    onSubmit() {
      this.saveIndex();
    },
    saveIndex() {
      this.$store.commit('showOverlay');
      request({
        url: '/customerIndex/updateCustomerIndex',
        method: 'post',
        data: this.indexInfo,
      })
        .then((res) => {
          this.$store.commit('hideOverlay');
        },rej=>{
          this.$store.commit('hideOverlay');
        });
    },
    getIndex() {
      let app = this;
      request({
        url: '/customerIndex/getSelectIndexId',
        method: 'get',
        params: {
          id: this.id,
        },
      })
        .then((res) => {
          app.indexInfo = res.data.CustomerIndex;
            app.indexInfo.printscreenJson = JSON.parse(
              app.indexInfo.printscreenText
            );
            app.screenFile.push({ url: app.indexInfo.printscreen });
            app.indexInfo.printscreenJson.forEach((item) => {
              app.fileList.push({ url: item });
            });
        });
    },
    distribute(item) {
      this.showDisDialog = true;
      this.getMarketUserAll(this.indexInfo.marketId);
    },
    getMarketUserAll(marketId) {
      request({
        url: '/login/getMarketUserAll',
        method: 'get',
        params: {
          marketId: marketId,
          stagroid: 0,
        },
      })
        .then((res) => {
          this.market_groupMember_Users = res.data.userList;
        });
    },
    handlebind() {
      this.$store.commit('showOverlay');
      request({
        url: '/customerIndex/bindCustomerIndex',
        method: 'get',
        params: {
          id: this.id,
        },
      })
        .then((res) => {
          this.indexInfo.customerServiceId = this.user.id;
          this.$store.commit('hideOverlay');
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/deep/ .el-form-item {
  margin-bottom: 10px;
}
.rowTextArea::v-deep .van-field__value {
  border: 1px solid #ccc;
}
</style>
