import Vue from "vue";
import App from "./App.vue";
import store from './store/store.js'
import router from "./router";

import cookies from "vue-cookies";
import wx from "weixin-js-sdk";
import { Calendar } from "element-ui";
//import 'element-ui/lib/theme-chalk/index.css'
import "./assets/custom-vant.less";

import { Form, Field, Cell, CellGroup, Button, Picker, Popup, Uploader, Loading, Overlay, Grid, GridItem, Col, Row, Switch, Image, NavBar, Dialog, DropdownMenu, DropdownItem, Card, Tag, Icon, Notify, Checkbox, CheckboxGroup, Progress,NoticeBar } from "vant";
Vue.config.productionTip = false;

Vue.use(Form);
Vue.use(Field);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Button);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Uploader);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Col);
Vue.use(Row);
Vue.use(Switch);
Vue.use(Image);
Vue.use(NavBar);
Vue.use(Dialog);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Card);
Vue.use(Tag);
Vue.use(Icon);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Progress);
Vue.use(NoticeBar);
//Vue.use(ImagePreview);

Vue.use(cookies);

Vue.use(Calendar, { size: "medium " });
//Vue.component(Calendar.name, Calendar);
Vue.prototype.$wx = wx;
Vue.prototype.$Notify = Notify;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
