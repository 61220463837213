<template>
    <div style="height:100%">
      <router-view class="g-view-container"></router-view>
      <div class="g-footer-container">
          <tabbar />
        </div>
    </div>
</template>

<script>
import Tabbar from '@/components/tabbar';
export default {
  components: {
    Tabbar
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.g-view-container {
  height: calc(~'100% - 50px');
  margin-bottom: 50px;
  overflow: auto;
}

.g-footer-container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0 0 10px 0 hsla(0, 6%, 58%, 0.6);
  height: 50px;
  z-index: 999;
  background: lightgreen;
}
</style>
