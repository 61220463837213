<template>
  <div class="main">
    <div v-show="showInfo" style="position: relative">
      <strong style="display: block">上传成功</strong>
      <van-button type="primary" @click="step2">继续上传</van-button>
    </div>
    <van-form v-show="showForm" @submit="onSubmit">
      <van-field v-model="indexForm.marketName" is-link readonly name="picker" required label="市场" placeholder="选择市场" @click="showMarketPicker = true" />
      <van-field v-model="indexForm.operations" is-link readonly name="picker" required label="运营" placeholder="选择运营" @click="showUserPicker = true" />
      <van-field v-model="indexForm.channelText" is-link readonly name="picker" required label="线索渠道" placeholder="选择线索获取渠道" @click="showChannelPicker = true" />
      <van-field v-model="indexForm.channelAccountText" is-link readonly name="picker" label="渠道账号" placeholder="选择渠道账号" @click="showAccountPicker = true" />
      <van-field v-model="indexForm.contact" label="联系方式" placeholder="联系方式" required :rules="[{ required: true, message: '请填写联系方式' }]" />
      <van-field name="scorm" label="联系方式截图">
        <template #input>
          <van-uploader max-count="1" v-model="screenFile" accept="image/*" />
        </template>
      </van-field>
      <van-field v-model="indexForm.customerInfo" rows="2" autosize label="留言" type="textarea" maxlength="50" placeholder="请输入留言" show-word-limit />
      <van-field name="uploader" label="详情截图">
        <template #input>
          <van-uploader ref="fileUpload" multiple v-model="fileList" accept="image/*" />
        </template>
      </van-field>
      <div style="margin: 16px">
        <van-button block type="primary" native-type="submit"> 提交 </van-button>
      </div>
    </van-form>
    <van-popup v-model="showMarketPicker" position="bottom">
      <van-picker show-toolbar :columns="marketList" value-key="marketName" @confirm="onNConfirm" @cancel="showMarketPicker = false" />
    </van-popup>

    <van-popup v-model="showUserPicker" position="bottom">
      <van-picker show-toolbar :columns="userList" value-key="username" @confirm="onUConfirm" @cancel="showUserPicker = false" />
    </van-popup>

    <van-popup v-model="showChannelPicker" position="bottom">
      <van-picker show-toolbar :columns="channelList" value-key="text" @confirm="onCConfirm" @cancel="showChannelPicker = false" />
    </van-popup>

    <van-popup v-model="showAccountPicker" position="bottom">
      <van-picker show-toolbar :columns="accountList" value-key="text" @confirm="onAConfirm" @cancel="showAccountPicker = false" />
    </van-popup>
  </div>
</template>

<script>
import request from "@/api/request.js";
import { mapState } from 'vuex';
export default {
  name: "uploadIndex",
  computed: mapState(['user', 'marketList']),
  data() {
    return {
      showInfo: false,
      showForm: true,
      showUserPicker: false,
      showMarketPicker: false,
      showChannelPicker: false,
      showAccountPicker: false,
      fileList: [],
      screenFile: [],
      userList: [],
      channelList: [],
      accountList: [],
      defaultImg: {
        2: "https://mp.xycm99.cn/static/file/contact/066b12325707a67a5b08f4db7cebffa7.jpeg",
        4: "https://mp.xycm99.cn/static/file/contact/4016d5f937f3e9fd6cbb57f74a483289.jpeg",
      },
      indexForm: {
        id: "",
        marketId: "",
        marketName: "",
        channel: "",
        channelAccountId: "",
        channelText: "",
        operationsId: "",
        operations: "",
        contact: "",
        customerInfo: "",
        customerServiceId: "",
        customerService: "",
        wx: "",
        progressInfo: "",
        ok: "",
        statusCode: "danger",
        statusText: "待定",
        profit: "",
      },
    };
  },
  watch: {
    "indexForm.channel": "getAccountList",
  },
  activated(){
    let market = this.marketList.filter(item=>{ 
      return item.id == sessionStorage.getItem('market_id')});
    this.indexForm.marketId = market[0].id;
    this.indexForm.marketName = market[0].marketName;
  },
  created() {
    //默认选择session中的市场
    console.log('upload created');
    let market = this.marketList.filter(item=>{ 
      return item.id == sessionStorage.getItem('market_id')});
    this.indexForm.marketId = market[0].id;
    this.indexForm.marketName = market[0].marketName;
    this.loadChannel();
    this.loadUser(5);
  },
  methods: {
    getAccountList(newv, oldv) {
      if (newv != "") {
        request({
          url: "/channelAccount/getChannelByAccount",
          method: "get",
          params: { channelId: newv },
        })
          .then((res) => {
            this.accountList = res.data.accountlList;
            this.accountList.forEach((item) => {
              item.text = item.name;
            });
          });
      }
    },
    loadChannel() {
      request({
        url: "/channel/getAll",
        method: "get",
      })
        .then((res) => {
          this.channelList = res.data.channelList;
          this.channelList.forEach((item) => {
            item.text = item.name;
          });
        });
    },
    step2() {
      this.screenFile.length = 0;
      this.fileList.length = 0;
      this.indexForm.contact = "";
      this.indexForm.channelAccountId = "";
      this.indexForm.channelAccountText = "";
      this.showForm = true;
      this.showInfo = false;
    },
    onUConfirm(index) {
      this.indexForm.operations = index.username;
      this.indexForm.operationsId = index.id;
      this.showUserPicker = false;
    },
    onNConfirm(index) {
      sessionStorage.setItem('market_id',index.id);
      this.indexForm.marketId = index.id;
      this.indexForm.marketName = index.marketName;
      this.showMarketPicker = false;
    },
    onCConfirm(index) {
      this.indexForm.channel = index.id;
      this.indexForm.channelText = index.text;
      this.showChannelPicker = false;
    },
    onAConfirm(index) {
      this.indexForm.channelAccountId = index.id;
      this.indexForm.channelAccountText = index.text;
      this.showAccountPicker = false;
    },
    onSubmit() {
      this.indexForm.customerInfo = this.indexForm.marketName + "线索";
      this.$store.commit("showOverlay");
      let formdata = new FormData();
      for (let key in this.indexForm) {
        formdata.append(key, this.indexForm[key]);
      }
      if (this.screenFile.length > 0) {
        formdata.append("file", this.screenFile[0].file);
      } else {
        formdata.append("printscreen", this.defaultImg[this.indexForm.marketId]); //默认图片
      }
      for (let i = 0; i < this.fileList.length; i++) {
        formdata.append("files", this.fileList[i].file);
      }
      let app = this;
      request({
        url: "/customerIndex/insertCustomerIndex",
        data: formdata,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          this.$store.commit("hideOverlay");
          app.showForm = false;
          app.showInfo = true;
        },rej=>{
          this.$store.commit("hideOverlay");
        });
    },
    loadUser(stajurid) {
      let _this = this;
      request({
        url: "/login/getMarketUserAll",
        method: "get",
        params: {
          marketId: this.indexForm.marketId,
          stajurid: stajurid,
        },
      })
        .then((res) => {
          this.userList = res.data.userList;
          this.$set(this.indexForm, "operationsId", this.user.id);
          this.$set(this.indexForm, "operations", this.user.username);
          let index = this.userList.findIndex((item) => item.id == this.indexForm.operationsId);
          if (index < 0) {
            this.userList.push({
              id: this.indexForm.operationsId,
              username: this.indexForm.operations,
            });
          }
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
/deep/ .el-form-item {
  margin: 0px;
}
.c-form-item {
  padding: 10px;
  border-left: 1px solid #ebeef5;
  border-right: 1px solid #ebeef5;
}
.el-upload-list__item.is-ready .el-upload-list__item-status-label {
  display: block;
}
.main {
  // height: calc(~'100vh - 50px');
  background: #fafafa;
  position: relative;
}
</style>
